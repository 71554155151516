<template>
  <v-row class="flex-column pa-10">
    <v-card>
      <v-card-title>
        <v-col>
          <v-form ref="search_form">
            <v-row>
              <v-col cols="12" md="6" lg="6" xl="6">
                <v-autocomplete
                  outlined
                  clearable
                  label="Cari Menggunakan"
                  :items="search_form.ac_items"
                  v-model="search_form.vm_ac"
                  :disabled="active === true ? true : false"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="6">
                <v-text-field
                  outlined
                  clearable
                  label="Keyword"
                  v-model="search_form.vm_keyword"
                  :disabled="search_form.vm_ac === null ? true : false"
                  :hint="
                    search_form.vm_ac === 'Nomor Telpon'
                      ? 'Tambahkan kode area (Contoh: +62)'
                      : 'Nama Reseller'
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                depressed
                width="150"
                :disabled="search_form.vm_keyword === null ? true : false"
                color="brown lighten-1"
                @click="filter"
              >
                <span class="white--text"> Cari </span>
                <v-icon right color="white">mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                large
                text
                width="150"
                :disabled="search_form.vm_keyword === null ? true : false"
                color="secondary"
                @click="resetResult"
              >
                <span> Reset </span>
                <v-icon right>mdi-reload</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-card-subtitle>
          <h3>Total Reseller : {{member.total}}</h3>
        </v-card-subtitle>
        <v-alert
          border="top"
          colored-border
          elevation="2"
          color="brown lighten-1"
          v-if="active"
        >
          <br />
          <p class="text-center">Loading Data, Please Wait...</p>
          <br />
          <v-progress-linear
            indeterminate
            color="brown lighten-1"
            :active="active"
          >
          </v-progress-linear>
        </v-alert>
        <v-col v-for="(mem, i) in member.data" :key="i">
          <v-card>
            <v-card-title class="brown lighten-1">
              <v-row>
                <v-col>
                  <span class="white--text">
                    {{ mem.nama }}
                    <v-chip
                      dark
                      :color="
                        mem.status === 'Aktif'
                          ? 'green accent-4'
                          : 'amber accent-3'
                      "
                      class="ml-2"
                    >
                      <span>{{ mem.status }}</span>
                    </v-chip>
                  </span>
                </v-col>
                <v-col>
                  <v-btn
                    depressed
                    large
                    width="125"
                    class="float-right"
                    color="white"
                    @click="openDialog(mem)"
                  >
                    Edit
                    <v-icon right>mdi-file-edit</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <br />
              <v-row>
                <v-col cols="6" md="4" lg="2" xl="2">
                  <h3>ID</h3>
                  <br>
                  <p>
                    {{ mem.id }}
                  </p>
                </v-col>
                <v-col cols="6" md="4" lg="2" xl="2">
                  <h3>Username</h3>
                  <br>
                  <p>
                    {{ mem.username }}
                  </p>
                </v-col>
                <v-col cols="6" md="4" lg="2" xl="2">
                  <h3>Kota/Kabupaten</h3>
                  <br>
                  <p>
                    {{ mem.kota }}
                  </p>
                </v-col>
                <v-col cols="6" md="4" lg="2" xl="2">
                  <h3>No. Telepon</h3>
                  <br>
                  <p>
                    {{ mem.telepon }}
                  </p>
                </v-col>
                <v-col cols="6" md="4" lg="2" xl="2">
                  <h3>Alamat</h3>
                  <br>
                  <p>
                    {{ mem.alamat }}
                  </p>
                </v-col>
                <v-col cols="6" md="4" lg="2" xl="2">
                  <h3>Created At</h3>
                  <br>
                  <p>
                    {{ mem.created_at }}
                  </p>
                </v-col>
              </v-row>
              
            </v-card-text>
          </v-card>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-pagination
          v-model="member.current_page"
          :length="member.last_page"
          total-visible="7"
          color="brown lighten-1"
          @input="handlePage"
        ></v-pagination>
      </v-card-actions>
    </v-card>
    <br />
    <v-dialog
      dark
      v-model="dialog"
      @click:outside="area = null"
      max-width="800px"
    >
      <v-card>
        <v-card-title class="light-blue darken-3">
          <h3>Reseller Detail</h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-card-subtitle class="blue--text"
                  >Biodata Reseller</v-card-subtitle
                >
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="val.username"
                  label="Username"
                  outlined
                  rounded
                  color="light-blue accent-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="val.nama"
                  label="Nama*"
                  outlined
                  rounded
                  color="light-blue accent-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Alamat*"
                  v-model="val.alamat"
                  outlined
                  rounded
                  color="light-blue accent-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-radio-group v-model="area" @change="changeField" row>
                  <v-radio label="Dalam Negeri" value="inlander"></v-radio>
                  <v-radio label="Luar Negeri" value="outlander"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-slide-y-transition mode="out-in">
                  <v-autocomplete
                    :items="sel_item"
                    label="Kota*"
                    v-model="val.kota"
                    v-if="inlander"
                    outlined
                    rounded
                    clearable
                    color="light-blue accent-1"
                  ></v-autocomplete>
                  <v-text-field
                    outlined
                    rounded
                    clearable
                    label="Luar Negeri"
                    v-model="val.kota"
                    color="light-blue accent-1"
                    v-if="outlander"
                  ></v-text-field>
                </v-slide-y-transition>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Telepon*"
                  v-model="val.telepon"
                  outlined
                  rounded
                  color="light-blue accent-1"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="sel_status"
                  v-model="val.status"
                  label="Status*"
                  outlined
                  rounded
                  color="light-blue accent-1"
                ></v-select>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-card-subtitle class="blue--text"
                  >Link Reseller</v-card-subtitle
                >
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  rounded
                  v-model="val.link"
                  label="Link Reseller"
                  color="light-blue accent-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="val.url_tokopedia"
                  label="Link Tokopedia"
                  clearable
                  outlined
                  rounded
                  color="light-blue accent-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="val.url_bukalapak"
                  label="Link Bukalapak"
                  clearable
                  outlined
                  rounded
                  color="light-blue accent-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="val.url_lazada"
                  label="Link Lazada"
                  clearable
                  outlined
                  rounded
                  color="light-blue accent-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="val.url_shopee"
                  label="Link Shopee"
                  clearable
                  outlined
                  rounded
                  color="light-blue accent-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="val.url_facebook"
                  label="Link Facebook"
                  clearable
                  outlined
                  rounded
                  color="light-blue accent-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="val.url_instagram"
                  label="Link Instagram"
                  clearable
                  outlined
                  rounded
                  color="light-blue accent-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="val.url_tiktok"
                  label="Link Tiktok"
                  clearable
                  outlined
                  rounded
                  color="light-blue accent-1"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*Wajib Diisi</small>
        </v-card-text>
        <v-card-actions>
          <v-col cols="4">
            <v-btn
              color="blue-grey darken-1"
              block
              rounded
              dark
              @click="closeDialog"
            >
              Close
              <v-icon right> mdi-close </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              class="white--text"
              color="deep-orange darken-1"
              :loading="del_loading"
              :disabled="del_loading"
              block
              rounded
              @click="deleteData"
            >
              Delete
              <v-icon right> mdi-trash-can </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              color="light-blue lighten-3"
              :loading="up_loading"
              :disabled="up_loading"
              block
              rounded
              @click="updateData"
            >
              Save <v-icon right> mdi-content-save </v-icon>
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "reseller",
  data() {
    return {
      domain: {
        local: "http://127.0.0.1:8000",
        prod: process.env.VUE_APP_KB_API_URL,
      },
      search_form: {
        ac_items: ["Nomor Telpon", "Nama"],
        vm_ac: null,
        vm_keyword: null,
      },
      member: [],
      active: true,
      del_loading: false,
      up_loading: false,
      tableLoading: true,
      snackbar_txt: "",
      dialog: false,
      search: "",
      headers: [
        { text: "ID", value: "username" },
        {
          text: "Nama",
          align: "start",
          value: "nama",
        },
        { text: "Kota", value: "kota" },
        { text: "Telepon", value: "telepon" },
        { text: "Alamat", value: "alamat" },
        { text: "Status", value: "status" },
      ],
      resellers: [],
      val: {
        id: "",
        username: "",
        nama: "",
        alamat: "",
        kota: "",
        telepon: "",
        status: "",
        link: "",
        url_tokopedia: "",
        url_bukalapak: "",
        url_lazada: "",
        url_shopee: "",
        url_facebook: "",
        url_instagram: "",
        url_tiktok: "",
      },
      sel_kota: "",
      sel_item: [],
      sel_status: ["Aktif", "Tidak Aktif"],
      inlander: false,
      outlander: false,
      area: null,
    };
  },
  methods: {
    async getMember() {
      let response = await axios.get(`${this.domain.prod}/api/reseller`);

      this.member = response.data;

      this.active = false;
    },
    resetResult() {
      this.$refs.search_form.reset();
      this.getMember();
    },
    async handlePage(v) {
      this.active = true;
      this.member = [];
      let all_response = await axios.get(
        `${this.domain.prod}/api/reseller?page=${v}`
      );

      let by_name_response = await axios.get(
        `${this.domain.prod}/api/reseller/${this.search_form.vm_keyword}?page=${v}`
      );

      let by_phone_response = await axios.get(
        `${this.domain.prod}/api/reseller/phone/${this.search_form.vm_keyword}?page=${v}`
      );

      if (this.search_form.vm_keyword === null) {
        this.member = all_response.data;
        this.active = false;
      } else if (
        this.search_form.vm_ac === "Nama" &&
        this.search_form.vm_keyword !== null
      ) {
        this.member = by_name_response.data;
        this.active = false;
      } else if (
        this.search_form.vm_ac === "Nomor Telpon" &&
        this.search_form.vm_keyword !== null
      ) {
        this.member = by_phone_response.data;
        this.active = false;
      }
    },
    async filter() {
      if (
        this.search_form.vm_ac === "Nama" &&
        this.search_form.vm_keyword !== null
      ) {
        let response = await axios.get(
          `${this.domain.prod}/api/reseller/${this.search_form.vm_keyword}`
        );

        this.member = response.data;
      } else if (
        this.search_form.vm_ac === "Nomor Telpon" &&
        this.search_form.vm_keyword !== null
      ) {
        let response = await axios.get(
          `${this.domain.prod}/api/reseller/phone/${this.search_form.vm_keyword}`
        );

        this.member = response.data;
        console.log(response.data);
      } else {
        console.log("please choose the search type and type the keyword");
      }
    },
    getColor(status) {
      for (let i = 0; i <= this.resellers.length; i++) {
        if (status == "Aktif") {
          return "green accent-4";
        }
        return "red";
      }
    },
    refreshPage() {
      this.getMember();
    },
    
    async updateData() {
      this.up_loading = true;
      console.log(this.val);
      let vm = this;
      await axios
        .put(
          `${process.env.VUE_APP_KB_API_URL}/api/reseller/${this.val.id}`,
          {
            username: this.val.username,
            nama: this.val.nama,
            alamat: this.val.alamat,
            kota: this.val.kota,
            telepon: this.val.telepon,
            status: this.val.status,
            link: this.val.link,
            url_tokopedia: this.val.url_tokopedia,
            url_bukalapak: this.val.url_bukalapak,
            url_lazada: this.val.url_lazada,
            url_shopee: this.val.url_shopee,
            url_facebook: this.val.url_facebook,
            url_instagram: this.val.url_instagram,
            url_tiktok: this.val.url_tiktok,
          },
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          vm.dialog = false;
          console.log("Data Has Been Updated");
          vm.up_loading = false;
          location.reload();
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
          vm.up_loading = false;
        });
    },
    async deleteData() {
      this.del_loading = true;
      let vm = this;
      await axios
        .delete(
          `${process.env.VUE_APP_KB_API_URL}/api/reseller/${this.val.id}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          vm.dialog = false;
          vm.del_loading = false;
          location.reload();
        })
        .catch(function (error) {
          console.log(error);
          vm.del_loading = false;
        });
    },
    openDialog(value) {
      this.checkCity(value.kota);
      this.val.id = value.id;
      this.val.username = value.username;
      this.val.nama = value.nama;
      this.val.alamat = value.alamat;
      this.val.kota = value.kota;
      this.val.telepon = value.telepon;
      this.val.status = value.status;
      this.val.link = value.link;
      this.val.url_tokopedia = value.url_tokopedia;
      this.val.url_bukalapak = value.url_bukalapak;
      this.val.url_lazada = value.url_lazada;
      this.val.url_shopee = value.url_shopee;
      this.val.url_facebook = value.url_facebook;
      this.val.url_instagram = value.url_instagram;
      this.val.url_tiktok = value.url_tiktok;
      this.dialog = true;
      console.log("Dialog Opened");
      console.log(this.val);
    },
    closeDialog() {
      this.dialog = false;
      this.area = null;
    },
    changeField(found) {
      if (this.area === null) {
        if (found) {
          console.log("the person is inlander");
          this.outlander = false;
          this.inlander = true;
          this.area = "inlander";
        } else {
          console.log("the person is outlander");
          this.inlander = false;
          this.outlander = true;
          this.area = "outlander";
        }
      } else {
        if (this.area === "inlander") {
          this.inlander = true;
          this.outlander = false;
        } else {
          this.inlander = false;
          this.outlander = true;
        }
      }
    },
    async checkCity(city) {
      let response = await axios.get(`${this.domain.prod}/api/kota/${city}`);

      if (response.data.length === 1) {
        this.changeField(true);
      } else if (response.data.length === 0) {
        this.changeField(false);
      }
    },
    async getCities() {
      let response = await axios.get(`${this.domain.prod}/api/kota`);

      this.sel_item = response.data;
    },
  },
  created: function () {
    
    this.getCities();
    this.getMember();
  },
};
</script>